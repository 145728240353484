//multistep modal
sendEvent = function (step) {
  $('#EditRatingModal').trigger('next.m.' + step);
};

// mobile menu

moveElements();

function moveElements() {
  var desktop = checkWindowWidth(768);
  //var signInBox = $('.sign-in-box');
  var userDMenu = $('li.user-dropdown-menu-item');
  var userdBox = $('.dropdown-user-info');

  if (desktop) {
    //        signInBox.detach();
    userDMenu.detach();
    userdBox.detach();

    //      signInBox.insertAfter('.signin-box-move-desktop-helper');
    $('ul.user-dropdown-menu').append(userDMenu);
    $('ul.user-dropdown-menu').prepend(userdBox);
  } else {
    //    signInBox.detach();
    userDMenu.detach();
    userdBox.detach();

    //  signInBox.insertBefore('.mobile-menu-helper-bottom');
    userDMenu.insertBefore('.mobile-menu-helper-bottom');
    userdBox.insertAfter('.mobile-menu-helper-top');
  }
}

function checkWindowWidth(MqL) {
  //check window width (scrollbar included)
  var e = window,
    a = 'inner';
  if (!('innerWidth' in window)) {
    a = 'client';
    e = document.documentElement || document.body;
  }
  if (e[a + 'Width'] >= MqL) {
    return true;
  } else {
    return false;
  }
}

function viewMore(element, visibility) {
  if (visibility == 'hide') {
    $(element).parent('.view-more-parent').addClass('expanded');
    $(element).remove();
  } else if ($(element).hasClass('view-more')) {
    $(element).parent('.view-more-parent').addClass('expanded has-hide');
    $(element)
      .removeClass('view-more')
      .addClass('view-less')
      .html('- View Less');
  } else if ($(element).hasClass('view-less')) {
    $(element).parent('.view-more-parent').removeClass('expanded has-hide');
    $(element)
      .removeClass('view-less')
      .addClass('view-more')
      .html('+ View More');
  }
}

$(window).resize(function () {
  moveElements();
});

var courseSidebar = $('.course-sidebar');
var footer = $('.footer-area');
var courseHeader = $('.course-header-area');
var margin = 10;

if ($('div').hasClass('course-sidebar')) {
  var offsetTop = courseSidebar.offset().top + (193 - margin);
}

$(window).scroll(function () {
  if (courseSidebar.length == 0) courseSidebar = $('.course-sidebar');
  if (footer.length == 0) footer = $('.footer-area');
  if (courseHeader.length == 0) courseHeader = $('.course-header-area');
  if ($(window).scrollTop() > 80) {
    $('#stickMenu').addClass('top-zero');
    $('.hide-stickMenu').hide();
  } else {
    $('#stickMenu').removeClass('top-zero');
    $('.hide-stickMenu').show();
  }
  if (checkWindowWidth(1200)) {
    try {
      var scrollTop = $(window).scrollTop();
      if (footer.offset()) {
        var offsetBottom =
          footer.offset().top - (margin * 2 + courseSidebar.height());
        if (scrollTop > offsetTop && courseSidebar.hasClass('natural')) {
          courseSidebar
            .removeClass('natural')
            .addClass('fixed')
            .css('top', margin);
          courseHeader
            .clone()
            .addClass('duplicated')
            .insertAfter('.course-header-area');
        }
        if (offsetTop > scrollTop && courseSidebar.hasClass('fixed')) {
          courseSidebar
            .removeClass('fixed')
            .addClass('natural')
            .css('top', 'auto');
          $('.course-header-area.duplicated').remove();
        }
        if (scrollTop > offsetBottom && courseSidebar.hasClass('fixed')) {
          courseSidebar
            .removeClass('fixed')
            .addClass('bottom')
            .css('top', offsetBottom + margin - 400);
        }
        if (offsetBottom > scrollTop && courseSidebar.hasClass('bottom')) {
          courseSidebar
            .removeClass('bottom')
            .addClass('fixed')
            .css('top', margin);
        }
      }
    } catch (er) {
      console.log(er);
    }
  }
});

$(document).ready(function () {
  // //open search form
  // $('.mobile-search-trigger').on('click', function (event) {
  //     event.preventDefault();
  //     toggleSearch();
  // });
  // //mobile - open lateral menu clicking on the menu icon
  // $('.mobile-nav-trigger').on('click', function (event) {
  //     if (!checkWindowWidth(768)) event.preventDefault();
  //     $('.mobile-main-nav').addClass('nav-is-visible');
  //     toggleSearch('close');
  //     $('.mobile-overlay').addClass('is-visible');
  // });
  // //submenu items - go back link
  // $('.go-back').on('click', function (event) {
  //     event.preventDefault();
  //     $(this).parent('ul').addClass('is-hidden').parent('.has-children').parent('ul').removeClass('moves-out');
  // });
  // $('.go-back-menu').on('click', function (event) {
  //     event.preventDefault();
  //     $(this).parent('ul').addClass('is-hidden').parent('.has-children').parent('ul').removeClass('moves-out').addClass('is-hidden').parent('.has-children').parent('ul').removeClass('moves-out');
  // });
  // //open submenu
  // $('.has-children').children('a').on('click', function (event) {
  //     event.preventDefault();
  //     var selected = $(this);
  //     if (selected.next('ul').hasClass('is-hidden')) {
  //         //desktop version only
  //         selected.addClass('selected').next('ul').removeClass('is-hidden').end().parent('.has-children').parent('ul').addClass('moves-out');
  //         selected.parent('.has-children').siblings('.has-children').children('ul').addClass('is-hidden').end().children('a').removeClass('selected');
  //         $('.mobile-overlay').addClass('is-visible');
  //     } else {
  //         selected.removeClass('selected').next('ul').addClass('is-hidden').end().parent('.has-children').parent('ul').removeClass('moves-out');
  //         $('.mobile-overlay').removeClass('is-visible');
  //     }
  //     toggleSearch('close');
  // });
  // //close lateral menu on mobile
  // $('.mobile-overlay').on('click', function () {
  //     closeNav();
  //     $('.mobile-overlay').removeClass('is-visible');
  // });
  // //prevent default clicking on direct children of .mobile-main-nav
  // $('.mobile-main-nav').children('.has-children').children('a').on('click', function (event) {
  //     event.preventDefault();
  // });
  // function toggleSearch(type) {
  //     if (type == "close") {
  //         //close serach
  //         $('.mobile-search').removeClass('is-visible');
  //         $('.mobile-search-trigger').removeClass('search-is-visible');
  //     } else {
  //         //toggle search visibility
  //         $('.mobile-search').toggleClass('is-visible');
  //         $('.mobile-search-trigger').toggleClass('search-is-visible');
  //     }
  // }
  // function closeNav() {
  //     // $('.mobile-nav-trigger').removeClass('nav-is-visible');
  //     $('.mobile-main-nav').removeClass('nav-is-visible');
  //     setTimeout(function () { $('.has-children ul').addClass('is-hidden'); }, 600);
  //     $('.has-children a').removeClass('selected');
  //     $('.moves-out').removeClass('moves-out');
  // }
  // $(function () {
  //     $('[data-toggle="tooltip"]').tooltip()
  // })
  // course carousel
  // $('.course-carousel').slick({
  //     dots: false,
  //     infinite: false,
  //     speed: 300,
  //     slidesToShow: 6,
  //     slidesToScroll: 6,
  //     swipe: false,
  //     touchMove: false,
  //     responsive: [
  //         {
  //             breakpoint: 1300,
  //             settings: {
  //                 slidesToShow: 5,
  //                 slidesToScroll: 5,
  //             }
  //         },
  //         {
  //             breakpoint: 1100,
  //             settings: {
  //                 slidesToShow: 4,
  //                 slidesToScroll: 4
  //             }
  //         },
  //         {
  //             breakpoint: 840,
  //             settings: {
  //                 slidesToShow: 3,
  //                 slidesToScroll: 3
  //             }
  //         },
  //         {
  //             breakpoint: 620,
  //             settings: {
  //                 slidesToShow: 2,
  //                 slidesToScroll: 2
  //             }
  //         },
  //         {
  //             breakpoint: 480,
  //             settings: {
  //                 slidesToShow: 1,
  //                 slidesToScroll: 1
  //             }
  //         }
  //     ]
  // });
  //tinymce editor
  // tinymce.init({
  //     selector: '.author-biography-editor',
  //     menubar: false,
  //     statusbar: false,
  //     branding: false,
  //     toolbar: 'bold  italic'
  // });
  // $('.select2').select2({
  //     width: 'resolve',
  //     placeholder: "Type a user's name",
  // });
  // $('a.has-popover').webuiPopover({
  //     trigger: 'hover',
  //     placement: 'horizontal',
  //     delay: {
  //         show: 300,
  //         hide: null
  //     },
  //     width: 335
  // });
  // if ($('div').hasClass('course-preview-video')) {
  //     jwplayer("course-preview-video").setup({
  //         "file": "http://www.sample-videos.com/video/mp4/720/big_buck_bunny_720p_1mb.mp4",
  //         "image": "http://mrfatta.com/wp-content/uploads/2015/05/CarWrap_Sample.jpg",
  //         "width": "100%",
  //         aspectratio: "16:9",
  //         listbar: {
  //             position: 'right',
  //             size: 260
  //         },
  //     });
  // }
});
function hideChat() {
  $('body').addClass('hideChat');
}
function readyFunction(type) {
  /* $(".course-box").mouseleave(function () {
        $('.webui-popover').remove();
    }); */
  // if (checkWindowWidth(800)) {
  //   $('a.has-popover').webuiPopover({
  //     trigger: 'hover',
  //     placement: 'horizontal',
  //     delay: {
  //       show: 300,
  //       hide: 300
  //     },
  //     width: 335
  //     /*  dismissible:true, // if popover can be dismissed by  outside click or escape key
  //            autoHide:true, */
  //   });
  // }
  if (type == 'menu') {
    //open search form
    $('.mobile-search-trigger').on('click', function (event) {
      event.preventDefault();
      toggleSearch();
    });

    //mobile - open lateral menu clicking on the menu icon
    $('.mobile-nav-trigger').on('click', function (event) {
      if (!checkWindowWidth(768)) event.preventDefault();
      $('.mobile-main-nav').addClass('nav-is-visible');
      toggleSearch('close');
      $('.mobile-overlay').addClass('is-visible');
    });

    //submenu items - go back link
    $('.go-back').on('click', function (event) {
      event.preventDefault();
      $(this)
        .parent('ul')
        .addClass('is-hidden')
        .parent('.has-children')
        .parent('ul')
        .removeClass('moves-out');
    });
    $('.go-back-menu').on('click', function (event) {
      event.preventDefault();
      $(this)
        .parent('ul')
        .addClass('is-hidden')
        .parent('.has-children')
        .parent('ul')
        .removeClass('moves-out')
        .addClass('is-hidden')
        .parent('.has-children')
        .parent('ul')
        .removeClass('moves-out');
    });

    //open submenu
    $('.has-children')
      .children('a')
      .on('click', function (event) {
        event.preventDefault();
        var selected = $(this);
        if (selected.next('ul').hasClass('is-hidden')) {
          //desktop version only
          selected
            .addClass('selected')
            .next('ul')
            .removeClass('is-hidden')
            .end()
            .parent('.has-children')
            .parent('ul')
            .addClass('moves-out');
          selected
            .parent('.has-children')
            .siblings('.has-children')
            .children('ul')
            .addClass('is-hidden')
            .end()
            .children('a')
            .removeClass('selected');
          $('.mobile-overlay').addClass('is-visible');
        } else {
          selected
            .removeClass('selected')
            .next('ul')
            .addClass('is-hidden')
            .end()
            .parent('.has-children')
            .parent('ul')
            .removeClass('moves-out');
          $('.mobile-overlay').removeClass('is-visible');
        }
        toggleSearch('close');
      });

    //close lateral menu on mobile
    $('.mobile-overlay').on('click', function () {
      closeNav();
      $('.mobile-overlay').removeClass('is-visible');
    });

    //prevent default clicking on direct children of .mobile-main-nav
    $('.mobile-main-nav')
      .children('.has-children')
      .children('a')
      .on('click', function (event) {
        event.preventDefault();
      });

    function toggleSearch(type) {
      if (type == 'close') {
        //close serach
        $('.mobile-search').removeClass('is-visible');
        $('.mobile-search-trigger').removeClass('search-is-visible');
      } else {
        //toggle search visibility
        $('.mobile-search').toggleClass('is-visible');
        $('.mobile-search-trigger').toggleClass('search-is-visible');
      }
    }

    function closeNav() {
      // $('.mobile-nav-trigger').removeClass('nav-is-visible');
      $('.mobile-main-nav').removeClass('nav-is-visible');
      setTimeout(function () {
        $('.has-children ul').addClass('is-hidden');
      }, 600);
      $('.has-children a').removeClass('selected');
      $('.moves-out').removeClass('moves-out');
    }
    moveElements();
  }
  // $('.course-carousel').slick({
  //   dots: false,
  //   infinite: false,
  //   speed: 300,
  //   slidesToShow: 5,
  //   slidesToScroll: 3,
  //   swipe: false,
  //   touchMove: false,
  //   responsive: [
  //     {
  //       breakpoint: 1300,
  //       settings: {
  //         slidesToShow: 5,
  //         slidesToScroll: 3
  //       }
  //     },
  //     {
  //       breakpoint: 1100,
  //       settings: {
  //         slidesToShow: 5,
  //         slidesToScroll: 3
  //       }
  //     },
  //     {
  //       breakpoint: 840,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3
  //       }
  //     },
  //     {
  //       breakpoint: 620,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // });
}
function callToolTip() { 
 // $('.popover').remove();
 

  setTimeout(function () {
    if (checkWindowWidth(800)) {
      // $('[data-toggle="popover"]').popover({
      //   trigger: 'hover',
      //   placement: 'right',
      //   html: true
      // });

      $('[data-toggle="popover"]').popover({
        trigger: 'hover',
        placement: 'bottom',
        container: 'body',
        sanitizer:false,
        content:function () {//console.log($(this).attr("data-content"));
        var decode = $("<div/>").html($(this).attr("data-content")).text();
        
          return unescape(decode);
      },
        html: true
      });
    } else {
      // $('[data-toggle="popover"]').popover({
      //   trigger: 'click',
      //   placement: 'bottom',
      //   html: true
      // });

      $('[data-toggle="popover"]').popover({
        trigger: 'click',
        placement: 'bottom',
        container: 'body',
        sanitizer:false,
        content:function () {//console.log($(this).attr("data-content"));
        var decode = $("<div/>").html($(this).attr("data-content")).text();
       
          return unescape(decode);
      },
        html: true
      });
    }
  }, 500);
}
function assignAccordion() {
  var idx = 0;
  $('[data-toggle="collapse"]').each(function () {
    $(this).attr('data-target', '#collapse' + idx);
    idx = idx + 1;
  });
  return true;
}

function removeStick() {
  setTimeout(() => {
    $('#stickMenu').removeClass('no-pad-commitments');
    $('#stickMenu').removeAttr('id');
  }, 1000);
}


function selectedSlide(idx){
  $($('.carousel-indicators li')[idx]).click();
}


function showPromotion(){

  let isAvailable=sessionStorage.getItem("promotion")
  if(!isAvailable){
    $('#promotionContent').modal('show');
    sessionStorage.setItem("promotion","true");
  }
}




	$(document).ready(function() {
	  var owl = $('.course-carousel');
	  owl.owlCarousel({
		items: 4,
		loop: true,
		margin: 30,
		autoplay: true,
		autoplayTimeout: 4000,
		autoplayHoverPause: true,
		responsive:{
			0:{
				items:1,
				nav:false
			},
			600:{
				items:2,
				nav:false
			},
			1000:{
				items:3,
				nav:false,
				loop:false
			},
			1200:{
				items:4,
				nav:false,
				loop:false
			}
		}
	  }); 
	})

	$(document).ready(function() {
	  var owl = $('.testimonial-carousel');
	  owl.owlCarousel({
		items: 1,
		loop: true,
		margin: 30,
		autoplay: true,
		autoplayTimeout: 4000,
		autoplayHoverPause: true,
		responsive:{
			0:{
				items:1,
				nav:false
			},
			600:{
				items:1,
				nav:false
			},
			1000:{
				items:1,
				nav:false,
				loop:false
			}
		}
	  }); 
	})

